<template>
	<v-container>
		<v-form style="max-width: 600px; margin: auto" class="pa-2" @submit.prevent="login" ref="login">
			<v-img
				class="logo mb-3"
				contain
				:src="`./img/logo_${$store.getters.getNombreEmpresa}.png`"
				max-height="300"
				alt="logo"
			/>
			<v-card>
				<v-sheet color="primary">
					<v-card-title class="white--text">Portal de {{ portal }}</v-card-title>
				</v-sheet>
				<div class="pa-4 pt-0">
					<v-alert class="mt-2" v-if="error" type="error">{{ error }}</v-alert>
					<!-- <v-select
						:items="empresas"
						v-model="empresa"
						label="Selecciona una empresa"
						filled
						dense
						hide-details="auto"
						class="my-4"
						:rules="[rules.req]"
						item-text="Nombre"
						return-object
					>
					</v-select>-->

					<v-item-group
						:class="`${!development ? 'd-none' : 'd-flex'} my-2 justify-space-around`"
						mandatory
						v-model="empresa"
					>
						<v-item v-for="e in empresas" :key="e.IdEmpresa" :value="e" v-slot="{ active, toggle }">
							<v-card
								height="125"
								width="125"
								outlined
								:color="active ? 'primary' : ''"
								@click="toggle"
								class="d-flex flex-column justify-center"
								style="aspect-ratio: 1; border: 1px solid var(--v-primary-base);"
							>
								<div class="d-flex justify-center">
									<v-icon :color="active ? 'white' : 'primary'" x-large>
										{{
											getEntorno(e) == "Luz" ? "mdi-lightning-bolt" : "mdi-fire"
										}}
									</v-icon>
								</div>
								<div
									:class="`text-center text-h6 ${active ? 'white' : 'primary'
									}--text`"
								>{{ getEntorno(e) }}</div>
							</v-card>
						</v-item>
					</v-item-group>

					<v-text-field
						v-model="usuario"
						filled
						label="usuario"
						name="username"
						type="text"
						dense
						hide-details="auto"
						class="my-4"
						:rules="[rules.req]"
					></v-text-field>
					<v-text-field
						v-model="pass"
						filled
						label="contraseña"
						name="password"
						type="password"
						dense
						hide-details="auto"
						class="my-4"
						:rules="[rules.req]"
					></v-text-field>
					<v-btn
						type="submit"
						block
						color="primary"
						:loading="loading"
						:disabled="empresas.length == 0"
					>Enviar</v-btn>
				</div>
			</v-card>
		</v-form>

		<v-dialog :value="!!msg" max-width="600" persistent>
			<v-card class="pa-4">
				<v-card-title v-text="msg" />
				<v-card-actions>
					<v-spacer />
					<v-btn color="primary" @click="msg = false">Aceptar</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</v-container>
</template>

<script>
import { processToken } from "@/utils/index.js";
import { req } from "@/utils/validations.js";

export default {
	data() {
		return {
			usuario: null,
			pass: null,
			error: false,
			empresa: null,
			loading: false,
			modalEmpresa: false,
			msg: this.$route.query.msg,
			development: process.env.NODE_ENV == "development",
			portal: window.location.host.replaceAll(/www./g, "").split(".")[0],
			empresas: [],
			rules: {
				req,
			},
		};
	},
	mounted() {
		axios({
			method: "GET",
			url: `${process.env.VUE_APP_API_URL}/Empresa/GetDTOAll`,
		}).then(({ data }) => {
			this.empresas = processToken(data).iss.reverse();
		});
	},
	methods: {
		getEntorno({ CodSectorEmpresarial }) {
			return CodSectorEmpresarial == 2 ? "Luz" : "Gas";
		},
		login() {
			if (!this.$refs["login"].validate()) return;
			this.loading = true;
			this.$store
				.dispatch("login", {
					user: this.usuario,
					pass: this.pass,
					idEmpresa: (this.empresa || this.empresas[0]).IdEmpresa,
					CodSectorEmpresarial: (this.empresa || this.empresas[0]).CodSectorEmpresarial,
				})
				.then(() => {
					this.$router.push(this.$route.query.redirect || "/");
					this.loading = false;
				})
				.catch((err) => {
					console.log(err, err.response)
					if (err.response.status && err.response.status == 400) {
						this.error = `Esta cuenta no tiene acceso a ${this.getEntorno({ CodSectorEmpresarial: (this.empresa || this.empresas[0]).CodSectorEmpresarial})}`;
					} else this.error = 'Los datos de acceso no son correctos';
					this.loading = false;
					this.pass = "";
				});
		},
	},
};
</script>

<style>
.logo {
	max-width: 450px;
	margin: auto;
}
</style>
